import { trpc } from 'lib/trpc'
import { createPage } from 'lib/util/create-page'
import { HomeScreen } from 'screens/home/home'

export default function IndexPage() {
  return createPage<typeof HomeScreen>({
    component: HomeScreen,
    requireLogin: true,
    hook: () => {
      const lectureCollectionQuery = trpc.useQuery(['lecture.findMany'])
      return {
        lectureCollection: lectureCollectionQuery.data,
      }
    },
  })
}
