import NextLink from 'next/link'
import { Box, Heading, Wrap, WrapItem, Link } from '@chakra-ui/react'
import { Layout } from 'components/layout/student'
import { Card, CardSkelton } from 'components/lecture/card'
import { InferQueryOutput } from 'lib/trpc/helper'
interface Props {
  lectureCollection?: InferQueryOutput<'lecture.findMany'>
}
export function HomeScreen({ lectureCollection }: Props) {
  return (
    <Layout>
      <Heading mb={4} as="h2" fontSize={'2xl'}>
        講義一覧
      </Heading>
      <Wrap spacing={0}>
        {lectureCollection ? (
          lectureCollection.map((lecture) => (
            <WrapItem
              width={['full', '33.33333%']}
              key={`lecture-card-${lecture.id}`}
            >
              <NextLink href={`/lectures/${lecture.id}`} passHref>
                <Link width={'full'} _hover={{}}>
                  <Box px={2} mb={6} width={'full'}>
                    <Card {...lecture} />
                  </Box>
                </Link>
              </NextLink>
            </WrapItem>
          ))
        ) : (
          <>
            <WrapItem width={['full', '33.33333%']} px={2}>
              <CardSkelton />
            </WrapItem>
            <WrapItem width={['full', '33.33333%']} px={2}>
              <CardSkelton />
            </WrapItem>
            <WrapItem width={['full', '33.33333%']} px={2}>
              <CardSkelton />
            </WrapItem>
          </>
        )}
      </Wrap>
    </Layout>
  )
}
