import NextImage from 'next/image'
import {
  Box,
  Heading,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Lecture } from '@prisma/client'
import { timeFormat } from 'lib/util/time'

interface Props extends Lecture {
  thumbnailLink?: string
}
export function Card({
  title,
  thumbnailLink = '/images/image.jpeg',
  duration,
  description,
}: Props) {
  return (
    <Box>
      <Box position={'relative'} height={'32'}>
        <NextImage src={thumbnailLink} layout="fill" objectFit="cover" />
        <Box position={'absolute'} bottom={2} right={2}>
          <Box backgroundColor={'black'} px={2} color={'white'}>
            <Text fontWeight={'bold'} fontSize={'small'}>
              {timeFormat(duration)}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box py={4}>
        <VStack spacing={1} alignItems={'flex-start'}>
          <Heading as="h2" size="md">
            {title}
          </Heading>
          <Text fontSize={'sm'} color={'gray.700'} noOfLines={2} wordBreak={"break-word"}>
            {description}
          </Text>
        </VStack>
      </Box>
    </Box>
  )
}

export function CardSkelton() {
  return (
    <Box width={'full'}>
      <Skeleton height={'32'} />
      <SkeletonText mt="4" noOfLines={3} spacing="2" />
    </Box>
  )
}
