export function timeFormat(time: number) {
  const date = new Date(0)
  date.setSeconds(time) // specify value for SECONDS here
  const timeString = date.toISOString().substring(11, 19)
  function pad(noPadTimeString: string): string {
    if (noPadTimeString.length < 5) {
      return noPadTimeString
    }
    const firstChar = noPadTimeString[0]
    if (firstChar === '0' || firstChar === ':') {
      return pad(noPadTimeString.substring(1))
    }
    return noPadTimeString
  }
  return pad(timeString)
}
